
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Tabs from '@/components/tabs.vue';
  import IreValuesRecords from '@/components/ire/IreValues.vue';

  export default defineComponent({
    name: 'ire-sizes-values',
    components: {
      Field,
      Form,
      Tabs,
      IreValuesRecords,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const selectIreArea = ref<number>(0);
      const loading = ref(false);
      const selectedPermissions = ref([]);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('ireSizesValues'), [
          translate('ire'),
        ]);
      });

      const changeArea = async (id) => {
        loading.value = true;
        await store.dispatch(Actions.GET_IRE_SIZE_AREAS_VALUES, id);
        loading.value = false;
      };

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmitUpdate = async (values) => {
        loading.value = true;
        const payload = {
          areaId: selectIreArea.value,
          ireSizeAreasValues: values,
        };
        await store.dispatch(Actions.UPDATE_IRE_SIZE_FACTOR_VALUES, payload);

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          loading.value = false;
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_IRE_SIZE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            selectIreArea.value = 0;
            router.push({ name: 'ire-sizes-listing' });
          });
        }
      };

      await store.dispatch(Actions.GET_ALL_IRE_AREAS);
      const tableHeader = ref([
        {
          name: translate('title'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('value'),
          key: 'value',
          sortable: true,
        },
        {
          name: '',
          key: 'actions',
          sortable: true,
        },
      ]);

      const updateIreSizeValuesList = (list) => {
        selectedPermissions.value = list;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.ireSizesAreasValuesList),
        ireAreas: computed(() => store.getters.ireAreasAllList),
        onSubmitUpdate,
        translate,
        can,
        selectIreArea,
        tableHeader,
        changeArea,
        loading,
        updateIreSizeValuesList,
      };
    },
  });
