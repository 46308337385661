import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "collapse show" }
const _hoisted_6 = { class: "card-body border-top p-9" }
const _hoisted_7 = { class: "row mb-6" }
const _hoisted_8 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_9 = ["title"]
const _hoisted_10 = { class: "col-lg-8 fv-row" }
const _hoisted_11 = {
  disabled: "",
  selected: "",
  value: "0"
}
const _hoisted_12 = ["value"]
const _hoisted_13 = {
  key: 1,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_14 = { class: "card-header border-0" }
const _hoisted_15 = { class: "card-title m-0" }
const _hoisted_16 = { class: "fw-bolder m-0" }
const _hoisted_17 = { class: "collapse show" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!
  const _component_IreValuesRecords = _resolveComponent("IreValuesRecords")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Tabs, {
      tableUrl: "/manage/ire/sizes-listing",
      areaValueUrl: "/manage/ire/sizes-values",
      tableTitle: "Size List",
      areaValueTitle: "Size Values"
    }),
    (_ctx.can('read', 'instant-rent-estimator'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate('InstantValueSizePanel')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Form, { class: "form" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("label", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.translate('areas')), 1),
                      _createElementVNode("i", {
                        class: "fas fa-exclamation-circle ms-1 fs-7",
                        "data-bs-toggle": "tooltip",
                        title: _ctx.translate('ireAreaTooltip')
                      }, null, 8, _hoisted_9)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_Field, {
                        as: "select",
                        name: "areaId",
                        class: "form-select form-select-solid form-select-lg text-capitalize",
                        modelValue: _ctx.selectIreArea,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectIreArea) = $event)),
                        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeArea(_ctx.selectIreArea)))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("option", _hoisted_11, _toDisplayString(_ctx.translate('selectIreArea')), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ireAreas, (area) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: area.id,
                              value: area.id
                            }, _toDisplayString(area.areaName), 9, _hoisted_12))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.can('read', 'instant-rent-estimator'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("h3", _hoisted_16, _toDisplayString(_ctx.translate('appartmentSize')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_IreValuesRecords, {
              "table-data": _ctx.tableData,
              "table-header": _ctx.tableHeader,
              loading: _ctx.loading,
              "area-id": _ctx.selectIreArea,
              onListValIre: _ctx.updateIreSizeValuesList,
              onSubmited: _ctx.onSubmitUpdate,
              "card-classes": "mb-6 mb-xl-9"
            }, null, 8, ["table-data", "table-header", "loading", "area-id", "onListValIre", "onSubmited"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}