
  import { defineComponent, ref, watch } from 'vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { Form, Field } from 'vee-validate';
  import { useI18n } from 'vue-i18n/index';
  import { useRouter } from 'vue-router';
  import { useAbility } from '@casl/vue';
  interface IHeaderConfiguration {
    name?: string;
    key: string;
    sortingField?: string;
    sortable?: boolean;
  }
  interface IreFactorValueObject {
    id: number;
    sizeId: number;
    typeId: number;
    value: number | null;
    name: string;
  }

  export default defineComponent({
    name: 'ire-values-records',
    emit: ['list-val-ire', 'submited'],
    props: {
      cardClasses: String,
      tableHeader: {
        type: Array as () => Array<IHeaderConfiguration>,
        required: true,
      },
      tableData: {
        type: Array as () => Array<IreFactorValueObject>,
        required: true,
      },
      loading: { type: Boolean, required: false },
      areaId: { type: Number, required: true },
    },

    components: {
      Datatable,
      Form,
      Field,
    },
    setup(props, { emit }) {
      const { t, te } = useI18n();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const router = useRouter();
      const { can } = useAbility();

      watch(
        () => props.tableData,
        (changed) => {
          emit('list-val-ire', changed);
        },
        { deep: true }
      );

      const changeTableData = async (val) => {
        props.tableData.map((data) => {
          if (data.sizeId === val.sizeId && data.typeId === val.typeId) {
            data.value = val.value === '' ? null : Number(val.value);
          }
        });
      };
      const goBack = () => {
        router.back();
      };

      const onSubmitUpdate = () => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        emit('submited', props.tableData);
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      return {
        translate,
        changeTableData,
        onSubmitUpdate,
        submitButton,
        goBack,
        can,
      };
    },
  });
